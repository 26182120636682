import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { getMetadata } from 'helpers';
import { useStore, useProjectCategoriesData } from 'src/hooks';
import { PageTemplate } from 'gatsby-theme-ww-prismic/src/templates/page';
export var DesignCategoryTemplate = function DesignCategoryTemplate(_ref) {
  var _metadata$primary, _metadata$primary$pro, _metadata$primary$pro2, _metadata$primary2;

  var data = _ref.data,
      location = _ref.location,
      props = _objectWithoutPropertiesLoose(_ref, ["data", "location"]);

  var metadata = getMetadata('PrismicPageMainDesignCategory', data === null || data === void 0 ? void 0 : data.prismicPage);
  var categoryUID = metadata === null || metadata === void 0 ? void 0 : (_metadata$primary = metadata.primary) === null || _metadata$primary === void 0 ? void 0 : (_metadata$primary$pro = _metadata$primary.project_category) === null || _metadata$primary$pro === void 0 ? void 0 : (_metadata$primary$pro2 = _metadata$primary$pro.document) === null || _metadata$primary$pro2 === void 0 ? void 0 : _metadata$primary$pro2.uid;
  var useLoadMore = (metadata === null || metadata === void 0 ? void 0 : (_metadata$primary2 = metadata.primary) === null || _metadata$primary2 === void 0 ? void 0 : _metadata$primary2.use_load_more_feature) == 'True';
  var projectCategory = useProjectCategoriesData(categoryUID);
  var injectSlices = useCallback(function (list) {
    if (list === void 0) {
      list = [];
    }

    return [{
      __typename: 'PageBodyHeader',
      id: 'header'
    }, {
      __typename: 'PageBodyHorizontalNavigation',
      id: 'horizontal-navigation',
      primary: {
        navigation: {
          document: data === null || data === void 0 ? void 0 : data.prismicNavigation
        }
      }
    }].concat(_toConsumableArray(list), [{
      __typename: 'PageBodyAllSmallProjects',
      id: 'all-small-projects',
      primary: {
        project_category: {
          uid: categoryUID
        },
        use_load_more: useLoadMore
      }
    }, {
      __typename: 'PageBodyFooter',
      id: 'footer'
    }]);
  }, [data, categoryUID]);

  var _useStore = useStore(function (state) {
    return {
      setActiveProjectCategoryUID: state.setActiveProjectCategoryUID,
      clearActiveProjectCategoryUID: state.clearActiveProjectCategoryUID
    };
  }, shallow),
      setActiveProjectCategoryUID = _useStore.setActiveProjectCategoryUID,
      clearActiveProjectCategoryUID = _useStore.clearActiveProjectCategoryUID;

  useEffect(function () {
    setActiveProjectCategoryUID(projectCategory === null || projectCategory === void 0 ? void 0 : projectCategory.uid);
    return function () {
      return clearActiveProjectCategoryUID();
    };
  }, [setActiveProjectCategoryUID, clearActiveProjectCategoryUID, data, projectCategory]);
  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    listMiddleware: injectSlices
  }, props));
};
export default DesignCategoryTemplate;
export var query = "4034652725";